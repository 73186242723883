@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    background-color: rgb(6, 200, 6);
    color: white;
}

::-webkit-scrollbar-track{
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
}   

::-webkit-scrollbar-thumb {
    background-color: rgb(6, 200, 6);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(0, 106, 0);
}